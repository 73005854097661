<div>
  <div class="skinny-footer-divider"></div>
  <div class="skinny-footer skinny-footer-ht flex flex-row">
    <div class="flex flex-row skinny-footer-content-left">
      <div class="flex flex-col">
        <span class="skinny-footer-content">Copyright © {{currYear}} Boeing. All rights reserved.</span>
        <div *ngIf="isEligibleUser" class="skinny-footer-build-info-icon" (click)="toggleCodeOff();">
          <span *ngIf="!isCodeOff" class="material-symbols-sharp">code</span>
          <span *ngIf="isCodeOff" class="material-symbols-sharp">code_off</span>
        </div>
      </div>
    </div>
    <div class="flex flex-row skinny-footer-content-right">
      <div class="flex flex-col pr-4">
        <div class="skinny-footer-content">
          <a class="cursor-pointer" (click)="getURL('#/Platform/termsOfUse','_blank')">Terms of Use</a>
        </div>
      </div>
      <div class="flex flex-col pr-4">
        <div class="skinny-footer-content-separator"></div>
      </div>
      <div class="flex flex-col">
        <div class="skinny-footer-content">
          <a class="cursor-pointer"
            (click)="getURL('http://www.boeing.com/privacy-and-cookie-policy.page','_blank')">Privacy Policy & Cookie
            Statement</a>
        </div>
      </div>
    </div>
  </div>
  <app-nxg-skinny-footer-build-info *ngIf="isCodeOff"></app-nxg-skinny-footer-build-info>
</div>

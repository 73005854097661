import { ChangeDetectorRef, Component, HostListener, Input, OnInit } from '@angular/core';
import { FooterBaseComponent } from '../footer-base.component';
import { FooterService } from '../footer.service';
import { AppConfigService } from 'src/app/core/app-config.service';
import { WorkspaceService } from 'src/app/workspace/workspace.service';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { UserApiService } from 'src/app/user/user-api.service';
import { User } from 'src/app/user/user';

@Component({
  selector: 'app-nxg-skinny-footer',
  templateUrl: './nxg-skinny-footer.component.html',
  styleUrls: ['./nxg-skinny-footer.component.scss']
})
export class NxgSkinnyFooterComponent extends FooterBaseComponent {

  isEligibleUser = false;
  isCodeOff = false;
  user: User;

  constructor(
    cd: ChangeDetectorRef,
    footerService: FooterService,
    appConfigService: AppConfigService,
    workspaceService: WorkspaceService,
    router: Router,
    location: Location,
    private userApiService: UserApiService
  ) {
    super(cd, footerService, appConfigService, router, location, workspaceService);
    this.userApiService.getUser().subscribe((user) => {
      this.user = user;
      if (this.user.userRoles.length > 0) {
        const findAdminRole = this.user.userRoles.find(function (item) {
          return item.userRoleName == 'PORTAL ADMINISTRATOR';
        });
        this.isEligibleUser = findAdminRole != undefined;
      }
    });
  }

  ngOnInit(): void {
    this.isEligibleUser = window.innerWidth > 1024 ? true : false;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.isEligibleUser = window.innerWidth > 1024 ? true : false;
  }

  toggleCodeOff() {
    this.isCodeOff = this.isCodeOff ? false : true;
  }

  getURL(url: string, urlTarget: string, type?: string, title?: string) {
    const value = window.location.href;
    if (value.includes(url)) {
      window.scroll(0, 0);
    } else {
      const updatedUrl = this.buildLink(url, type, title);
      window.open(updatedUrl, urlTarget);
    }
  }
}
